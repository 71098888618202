import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
// @mui
import LoadingButton from '@mui/lab/LoadingButton';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';

// utils
// routes
// types
// assets
// components
import { Checkbox, FormControl, FormLabel, MenuItem, TextField } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import FormProvider, {
  RHFMultiSelect,
  RHFSelect,
  RHFTextField
} from 'src/components/hook-form';
import { useSnackbar } from 'src/components/snackbar';
import { Upload } from 'src/components/upload';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { useLocales } from 'src/locales';
import { defaultLang } from "src/locales/config-lang";
import { useRouter } from 'src/routes/hook';
import { paths } from 'src/routes/paths';
import AppCurrentDownload from 'src/sections/overview/app/app-current-download';
import { getIngredientUnitString } from 'src/sections/user/NutritionHelper';
import { BudgetOptions, DifficultyLevelOptions, IRecipeCreate, IRecipeIngredient, IUserRecipe, Ingredient, MealTypeOptions, PreparationTimeOptions, SelectedIngredient } from 'src/types/recipe';
import { LoggedInUser } from 'src/types/user';
import axios, { endpoints } from 'src/utils/axios';
import { localStorageGetItem } from 'src/utils/storage-available';
import IngredientNewForm from './add-new-ingredient';
import Iconify from "../../../components/iconify";


// ----------------------------------------------------------------------

type Props = {
  selectedRecipe?: IUserRecipe;
};
type statistics = {
  total: string;
  EW: string;
  F: string;
  KH: string;
  totalCal: number;
  protein: number;
  fat: number;
  carbs: number;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function RecipeNewEditForm({ selectedRecipe }: Props) {


  const mockedUser = useMockedUser();
  const theme = useTheme();
  const WARNING_LIGHT = alpha(theme.palette.primary.main, 0.08);
  const { t } = useLocales();
  const loggedInUser = mockedUser.user as LoggedInUser;
  const router = useRouter();
  const [inputValue, setInputValue] = useState('');

  const { enqueueSnackbar } = useSnackbar();
  const newRecipe = useRef<number>(selectedRecipe?.id || 0);
  // const recipeId = useRef<number>(287599);

  const NewRecipeSchema = Yup.object().shape({
    title: Yup.string().required(t("ALERT_TITLE_REQ")),
    title_en: Yup.string(),
    budget: Yup.number().required('Budget is required'),
    difficulty: Yup.number().required('Difficulty Level is required'),
    prepTime: Yup.number().required('Preparation Time is required'),
    mealType: Yup.number().required('Meal Type is required'),
    advance: Yup.array().required('Diet Type is required'),
    preparation: Yup.string().required(t("ALERT_PREP_REQ")),
    preparation_en: Yup.string(),
  });
  // add type of array





  const defaultValues = useMemo(
    () => {
      const diet = [] as string[];
      if (selectedRecipe?.vegan === 1) {
        diet.push('vegan');
      }
      if (selectedRecipe?.paleo === 1) {
        diet.push('paleo');
      }
      if (selectedRecipe?.low_carb === 1) {
        diet.push('low_carb');
      }
      if (selectedRecipe?.keto === 1) {
        diet.push('keto');
      }
      if (selectedRecipe?.normalDiet === 1) {
        diet.push('normalDiet');
      }
      if (selectedRecipe?.pescetarian === 1) {
        diet.push('pescetarian');
      }
      if (selectedRecipe?.vegetarian === 1) {
        diet.push('vegetarian');
      }
      return {
        title: selectedRecipe?.title || '',
        title_en: selectedRecipe?.title_en || '',
        budget: selectedRecipe?.budget || 0,
        difficulty: selectedRecipe?.difficulty || 0,
        prepTime: selectedRecipe?.prepTime || 5,
        mealType: selectedRecipe?.mealType || 0,
        advance: selectedRecipe?.mealType ? diet : ['normalDiet'],
        preparation: selectedRecipe?.preparation || '',
        preparation_en: selectedRecipe?.preparation_en || '',
      }
    },
    [selectedRecipe]
  );

  const methods = useForm({
    resolver: yupResolver(NewRecipeSchema),
    defaultValues,
  });

  const [tempIngredients, setTempIngredients] = React.useState<{
    id: number;
    quantity: number;
    ingredientUnit: number;
    translationUnit?: number;
    translationAmmount?: number;
    steps:number;
  }>({
    id: -1,
    quantity: 0,
    ingredientUnit: -1,
    translationUnit: -1,
    translationAmmount: 0,
    steps:0
  });


  const [selectedFile, setSelectedFileFile] = useState<File | string | null>(null);

  const [selectedIngredients, setSelectedIngredients] = React.useState<SelectedIngredient[]>([]);
  const [generatingRecipe, setGeneratingRecipe] = React.useState<boolean>(false);
  const [statistics, setStatistics] = React.useState<statistics | undefined>();
  const [ingredients, setIngredients] = React.useState<Ingredient[]>([]);
  const [ingredientsLoading, setIngredientsLoading] = React.useState<boolean>(false);
  const [searchText, setSearchText] = useState('a');


  const {
    handleSubmit,
    formState: { isSubmitting },
    reset
  } = methods;

  const generateRecipe = useCallback(async () => {
    const createRecipeUrl = endpoints.recipe.createRecipe;
    const response = await axios.get(createRecipeUrl);
    type CreateRecipeResponse = {
      success: boolean;
      recipe: number;
    }
    if (response.status === 200) {
      const data: CreateRecipeResponse = response.data;
      if (data.success) {
        newRecipe.current = data.recipe;
      }
    }
  }, []);
  const getCalculatedData = useCallback(async () => {
    const recipeId = newRecipe.current || selectedRecipe?.id || 0;
    const calculateUrl = endpoints.recipe.addIngredientInRecipe;
    setGeneratingRecipe(true);
    const response = await axios.get(calculateUrl(recipeId));
    setGeneratingRecipe(false);
    type AddIngredientResponse = {
      success: boolean;
      data: {
        statistics: statistics;
        recipeIngredients: IRecipeIngredient[];
      }
    }
    if (response.status === 200) {
      const data: AddIngredientResponse = response.data;
      if (data.success && data.data.statistics) {
        setStatistics(data.data.statistics);
      }
      let savedId = -1;
      if (data.success && data.data.recipeIngredients.length) {
        data.data.recipeIngredients.forEach((item) => {
          if (item.fk_ingredientid === tempIngredients.id) {
            savedId = item.id;
          }
        });
      }
      console.log(savedId);
      if (savedId !== -1) {
        setSelectedIngredients([...selectedIngredients, {
          id: tempIngredients.id,
          ingredientUnit: tempIngredients.ingredientUnit,
          quantity: tempIngredients.quantity,
          translationUnit: tempIngredients.translationUnit,
          translationAmmount: tempIngredients.translationAmmount,
          name: ingredients.find((item) => item.id === tempIngredients.id)?.name || "",
          name_en: ingredients.find((item) => item.id === tempIngredients.id)?.name_en || "",
          savedId,
          steps:tempIngredients.steps
        }]);
        setTempIngredients({
          id: -1,
          quantity: 1,
          ingredientUnit: -1,
          translationUnit: -1,
          translationAmmount: 0,
          steps:0
        });
      }
    }
  }, [selectedIngredients, tempIngredients.id, tempIngredients.ingredientUnit, tempIngredients.quantity, tempIngredients.translationAmmount, tempIngredients.translationUnit, ingredients, selectedRecipe?.id]);
  const getEditedCalculatedData = useCallback(async () => {
    const recipeId = newRecipe.current || selectedRecipe?.id || 0;
    const calculateUrl = endpoints.recipe.addIngredientInRecipe;
    setGeneratingRecipe(true);
    const response = await axios.get(calculateUrl(recipeId));
    setGeneratingRecipe(false);
    type AddIngredientResponse = {
      success: boolean;
      data: {
        statistics: statistics;
        recipeIngredients: IRecipeIngredient[];
      }
    }
    if (response.status === 200) {
      const data: AddIngredientResponse = response.data;
      if (data.success && data.data.statistics) {
        setStatistics(data.data.statistics);

        const alreadySelectedIngredients: SelectedIngredient[] = [];
        data.data.recipeIngredients.forEach((item) => {
          console.log(item)
          alreadySelectedIngredients.push({
            id: item.fk_ingredientid,
            ingredientUnit: item?.ingredientUnit || 0,
            quantity: item.ammount,
            translationUnit: item.ingredient?.translationUnit || 0,
            translationAmmount: item.ingredient?.translationAmmount || 0,
            name: item?.name || "",
            name_en: item?.name_en || "",
            savedId: item.id,
            steps:item.steps
          });
        })
        setSelectedIngredients(alreadySelectedIngredients);
      }
      // let savedId = -1;
      // if (data.success && data.data.recipeIngredients.length) {
      //   data.data.recipeIngredients.forEach((item) => {
      //     if (item.fk_ingredientid === tempIngredients.id) {
      //       savedId = item.id;
      //     }
      //   });
      // }
      // console.log(savedId);
      // if (savedId !== -1) {
      //   setSelectedIngredients([...selectedIngredients, {
      //     id: tempIngredients.id,
      //     ingredientUnit: tempIngredients.ingredientUnit,
      //     quantity: tempIngredients.quantity,
      //     translationUnit: tempIngredients.translationUnit,
      //     translationAmmount: tempIngredients.translationAmmount,
      //     name: ingredients.find((item) => item.id === tempIngredients.id)?.name || "",
      //     name_en: ingredients.find((item) => item.id === tempIngredients.id)?.name_en || "",
      //     savedId,
      //     steps:tempIngredients.steps
      //   }]);
      //   setTempIngredients({
      //     id: -1,
      //     quantity: 1,
      //     ingredientUnit: -1,
      //     translationUnit: -1,
      //     translationAmmount: 0,
      //     steps:0
      //   });
      // }
    }
  }, [selectedIngredients, tempIngredients.id, tempIngredients.ingredientUnit, tempIngredients.quantity, tempIngredients.translationAmmount, tempIngredients.translationUnit, ingredients, selectedRecipe?.id]);
  useEffect(() => {
    if (selectedRecipe && selectedRecipe.id) {
      const alreadySelectedIngredients: SelectedIngredient[] = [];
      selectedRecipe.ingredient_in_recipes.forEach((item) => {
        console.log(item?.ingredient?.name)
        alreadySelectedIngredients.push({
          id: item.fk_ingredientid,
          ingredientUnit: item.ingredient?.ingredientUnit || 0,
          quantity: item.ammount,
          translationUnit: item.ingredient?.translationUnit || 0,
          translationAmmount: item.ingredient?.translationAmmount || 0,
          name: item?.ingredient?.name || "",
          name_en: item?.ingredient?.name_en || "",
          savedId: item.id,
          steps:item?.ingredient?.steps || 1
        });
      })
      setSelectedIngredients(alreadySelectedIngredients);
      const file = selectedRecipe.picture;
      if (file) {
        const baseUrl = `https://api2.nattygainscoaching.com/img/meals/${file}`;
        setSelectedFileFile(baseUrl);
      }
      getCalculatedData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRecipe])
  const addIngredientToRecipe = useCallback(async () => {
    const recipeId = newRecipe.current || selectedRecipe?.id || 0;
    const addIngredientInRecipeUrl = endpoints.recipe.addIngredientInRecipe;
    const data = {
      ingredient: tempIngredients.id,
      amount: tempIngredients.quantity,
      limitMin: tempIngredients.quantity,
      limitMax: tempIngredients.quantity,
      steps: 1,
      substituable: 0,
    }
    setGeneratingRecipe(true);
    const response = await axios.post(addIngredientInRecipeUrl(recipeId), { data });
    type AddIngredientResponse = {
      success: boolean;
      message: string[];
    }
    setGeneratingRecipe(false);
    if (response.status === 200) {
      const respData: AddIngredientResponse = response.data;
      if (respData.success) {
        enqueueSnackbar(t("ALERT_INGREDIENT_ADDED"));
        setTempIngredients({
          id: -1,
          quantity: 0,
          ingredientUnit: -1,
          translationUnit: -1,
          translationAmmount: 0,
          steps:0
        });
        getCalculatedData();
      } else {
        enqueueSnackbar(t("ING_ALREADY_EXIST"), { variant: 'error' });
      }
    }
  }, [tempIngredients?.id, tempIngredients?.quantity, enqueueSnackbar, getCalculatedData, t, selectedRecipe?.id]);
  const onSubmit = handleSubmit(async (data) => {
    try {
      let timeCost;
      if (data.prepTime <= 25) {
        timeCost = "0";
      } else if (data.prepTime > 25 && data.prepTime <= 50) {
        timeCost = "1";
      } else {
        timeCost = "2";
      }
      const dataToSend: IRecipeCreate = {
        title: data.title,
        title_en: data.title_en,
        budget: data.budget,
        difficulty: data.difficulty,
        prepTime: data.prepTime,
        mealType: data.mealType,
        advance: JSON.stringify(data.advance),
        preparation: data.preparation,
        preparation_en: data.preparation_en,
        timeCost,
        season_start: '0001-01-01',
        season_end: '0001-12-31',
        fk_tenant_id: loggedInUser.tenant_id.toString(),
        "tenants[0]": "active_ng",
        'basic[0]': '0',
        'basic[1]': '0',
        goals: 'tenant'
      }
      if (!selectedIngredients.length) {
        enqueueSnackbar(t("ALERT_ADD_ONE_INGREDIENT"), { variant: 'error' });
        return;
      }
      if (!selectedFile || (typeof selectedFile === 'string' && !selectedRecipe?.id)) {
        enqueueSnackbar(t("ALERT_ADD_IMAGE"), { variant: 'error' });
        return;
      }
      const form = new FormData();
      form.append("title", dataToSend.title);
      form.append("title_en", dataToSend.title_en);
      form.append("mealType", dataToSend.mealType.toString());
      form.append("season_start", "0001-01-01");
      form.append("season_end", "0001-12-31");
      form.append("timeCost", timeCost);
      form.append("budget", dataToSend.budget.toString());
      form.append("preparation", dataToSend.preparation);
      form.append("preparation_en", dataToSend.preparation_en);
      form.append("tenants[]", "active_ng");
      form.append("basic[]", "0");
      form.append("basic[]", "0");
      form.append("advance", JSON.stringify(data.advance));// check
      form.append("mealPrep", mealPrep === true ? "1" : "0");
      form.append("notCook", notCook === true ? "1" : "0");

      if (selectedFile && typeof selectedFile !== 'string') {
        const blob = new Blob([selectedFile]);
        let randomName = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
        if (typeof selectedFile === 'string') {
          randomName = selectedFile;
        } else {
          randomName = selectedFile.name;
        }
        form.append("picture", blob, randomName);
      }
      form.append("goals", "tenant");
      form.append("difficulty", dataToSend.difficulty.toString());
      form.append("prepTime", dataToSend.prepTime.toString());
      form.append("fk_tenant_id", loggedInUser.tenant_id.toString());
      form.append("email", loggedInUser.email);
      const storeRecipe = endpoints.recipe.storeRecipe;
      const recipeId = newRecipe.current || selectedRecipe?.id || 0;
      const response = await axios.post(storeRecipe(recipeId), form);
      if (response.status === 200) {
        enqueueSnackbar(selectedRecipe ? t("RECCIPE_UPDATE_SUCC") : t("RECIPE_CREATE_SUCC"));
        reset();
        router.push(paths.dashboard.recipe.root);
      } else {
        enqueueSnackbar('Something went wrong!!', { variant: 'error' });
      }
    } catch (error) {
      enqueueSnackbar('Something went wrong!!', { variant: 'error' });
      console.error(error);
    }
  });



  const getIngredietns = useCallback(async (newSearchText = "a") => {
    // setIngredientsLoading(true);
    const response = await axios.post(endpoints.recipe.searchIngredients, {
      token: "s6dfYvuypfb77W3A7KbOspF",
      q: newSearchText,
      mail_address_wp: loggedInUser?.email,
      id: loggedInUser?.tenant_id,
      lng: localStorageGetItem('i18nextLng', defaultLang.value)
    });
    // setIngredientsLoading(false);
    if (response.status === 200) {
      return response.data;
    }
    return [];
  }, [loggedInUser?.email, loggedInUser?.tenant_id])

  useEffect(() => {
    getIngredietns(searchText).then((data) => {
      if (data) {
        setIngredients(data);
      }
      setIngredientsLoading(false);
    }).catch((error) => {
      console.warn(error);
      setIngredientsLoading(false);
    });
  }, [getIngredietns, searchText]);





  const removeIngredient = useCallback(async (id: number) => {
    const removeIngredientUrl = endpoints.recipe.removeIngredient;
    try {
      const recipeId = newRecipe.current || selectedRecipe?.id || 0;
      const response = await axios.post(removeIngredientUrl(recipeId, id));
      type RemoveIngredientResponse = {
        success: boolean;
        message: string[];
      }
      if (response.status === 200) {
        const data: RemoveIngredientResponse = response.data;
        if (data.success) {
          enqueueSnackbar(t("ALERT_INGREDIENT_REMOVED"));
          setSelectedIngredients(selectedIngredients.filter((item) => item.id !== id));
          getCalculatedData();
        } else {
          enqueueSnackbar(data.message.join(" "), { variant: 'error' });
        }
      } else {
        enqueueSnackbar('Something went wrong!!', { variant: 'error' });
      }
    } catch (error) {
      console.warn(error);
      enqueueSnackbar('Something went wrong!!', { variant: 'error' });
    }

  }, [enqueueSnackbar, selectedIngredients, getCalculatedData, t, selectedRecipe?.id]);

  const editIngredient = useCallback(async (id: number,ammount:number) => {
    const editIngredientUrl = endpoints.recipe.editIngredient;
    try {
      const recipeId = newRecipe.current || selectedRecipe?.id || 0;
      const response = await axios.post(editIngredientUrl(recipeId, id,ammount));
      type RemoveIngredientResponse = {
        success: boolean;
        message: string[];
      }
      if (response.status === 200) {
        const data: RemoveIngredientResponse = response.data;
        if (data.success) {
          console.log(data)
          enqueueSnackbar(t("ALERT_INGREDIENT_UPDATED"));
           // setSelectedIngredients(selectedIngredients.filter((item) => item.id !== id));
           // setSelectedIngredients(data.recipeIngredients);
          getEditedCalculatedData();
        } else {
          enqueueSnackbar(data.message.join(" "), { variant: 'error' });
        }
      } else {
        enqueueSnackbar('Something went wrong!!', { variant: 'error' });
      }
    } catch (error) {
      console.warn(error);
      enqueueSnackbar('Something went wrong!!', { variant: 'error' });
    }

  }, [enqueueSnackbar, selectedIngredients, getCalculatedData, t, selectedRecipe?.id]);
  const [openAddNewIngredientDialog, setOpenAddNewIngredientDialog] = React.useState<boolean>(false);
  const isMobile = window.innerWidth <= 768;
  const [editMode, setEditMode] = useState<{ [key: number]: boolean }>({});
  const [editAmount, setEditAmount] = useState<{ [key: number]: number }>({});

  const handleEditClick = (ingredientId: number, amount: number) => {
    setEditMode({ ...editMode, [ingredientId]: true });
    setEditAmount({ ...editAmount, [ingredientId]: amount });
  };

  const handleCancelClick = (ingredientId: number) => {
    setEditMode({ ...editMode, [ingredientId]: false });
  };


  const handleSaveClick = (ingredientId: number) => {
    editIngredient(ingredientId, editAmount[ingredientId]);
    setEditMode({ ...editMode, [ingredientId]: false });
  }


  const renderIngredientsSelection = () => {
    if (ingredientsLoading) {
      return <div>Loading...</div>
    }
    const temp = ingredients.find((item) => item.id === tempIngredients.id);
    const isMobile = window.innerWidth <= 768;

    // Define the default styles
    const styles = {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      marginTop: "12px",
      gap: "16px",
    };

    // Override styles for mobile devices
    const mobileStyles = {
      display: isMobile ? "inline-grid" : "flex",
    };
    return (
      <div style={{ marginTop: "25px", background: theme.palette.mode === 'light' ? '#F9F9FA' : theme.palette.grey[700], padding: '20px', borderRadius: '10px' }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
          <h3>{t("HEADING_ADD_INGREDIENT")}</h3>
          <div>
            <IngredientNewForm open={openAddNewIngredientDialog} setOpenDialog={setOpenAddNewIngredientDialog} />
          </div>
        </div>
        <div style={{ ...styles, ...mobileStyles }}>

          <Autocomplete
            value={ingredients.find((item) => item.id === tempIngredients.id) || null}
            fullWidth
            autoHighlight
            options={ingredients}
            getOptionLabel={(option) => localStorageGetItem('i18nextLng', defaultLang.value) === 'en' ? option.name_en : option.name}
            onChange={(event: React.ChangeEvent<{}>, value) => {
              // Check if value is not null before comparing
              if (value !== null) {
                const selectedIngredient = ingredients.find((item) => item.id === (value as Ingredient)?.id);
                if (selectedIngredient) {
                  setTempIngredients({
                    id: selectedIngredient.id,
                    quantity: 1,
                    ingredientUnit: selectedIngredient.ingredientUnit,
                    translationUnit: selectedIngredient.translationUnit,
                    translationAmmount: selectedIngredient.translationAmmount,
                    steps:selectedIngredient.steps
                  });
                }
              }
            }}


            renderInput={(params) => (
              <TextField
                {...params}
                label={t("SEARCH_INGREDIENTS")}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password',
                }}
                style={{ background: theme.palette.mode === 'light' ? 'white' : theme.palette.grey[600] }}
                value={inputValue}
                onChange={(event) => {
                  const newSearchText = event.target.value;
                  // Call getIngredietns function with the searchText value
                  setSearchText(newSearchText);
                  setInputValue(newSearchText);

                }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                {localStorageGetItem('i18nextLng', defaultLang.value) === 'en' ? option.name_en : option.name}
              </li>
            )}
          />

          <TextField disabled={tempIngredients.id === -1} type='number' style={{ width: isMobile ? "100%" : "15%", background: theme.palette.mode === 'light' ? 'white' : theme.palette.grey[600] }} placeholder={t("ADD_AMT")} onChange={(event) => {
            setTempIngredients({
              ...tempIngredients,
              quantity: Number(event.target.value),
            });
          }} value={tempIngredients?.quantity || ""} />
          <TextField type='text' aria-readonly value={t(getIngredientUnitString(tempIngredients.ingredientUnit))} style={{ width: isMobile ? "100%" : "15%", background: theme.palette.mode === 'light' ? 'white' : theme.palette.grey[600] }} disabled />
          <LoadingButton loading={generatingRecipe} color='success' style={{ width: isMobile ? "100%" : "20%" }} variant='contained' onClick={async () => {
            if (!tempIngredients || (tempIngredients?.quantity === 0 || tempIngredients.quantity < 0)) {
              enqueueSnackbar(t("ALERT_QTY_REQ"), { variant: 'error' });
              return;
            }
            const recipeId = newRecipe.current || selectedRecipe?.id || 0;
            if (!recipeId) {
              setGeneratingRecipe(true);
              await generateRecipe();
              setGeneratingRecipe(false);
              if (!newRecipe.current) {
                enqueueSnackbar('Something went wrong!!', { variant: 'error' });
              }
            }
            await addIngredientToRecipe();

          }} disabled={!tempIngredients || (tempIngredients?.quantity === 0 || tempIngredients.quantity < 0 || (tempIngredients.id === -1))}>{t("BUTTON_INGREDIENT")}</LoadingButton>
        </div>
        <div style={{ display: 'flex' }}>
          <div style={{ display: 'flex', width: isMobile ? "100%" : "50%" }}>
            <p>{t("INGREDIENT_NOT_FOUND")} </p>
            <div
              role="button"
              tabIndex={0}
              onClick={() => setOpenAddNewIngredientDialog(true)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' || e.key === ' ') {
                  setOpenAddNewIngredientDialog(true);
                }
              }}
            >
              <p style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer', marginLeft: '10px' }} > {t("CREATE_NEW_ENTRY")}</p>
            </div>
          </div>
          {tempIngredients.translationAmmount !== 0 && tempIngredients.id<10000 && (
            <div style={{ display: 'flex', fontSize: '14px' }}>
              <p>
                {tempIngredients.translationAmmount} {' '} {t(getIngredientUnitString(tempIngredients?.ingredientUnit || -1))}
                {' = 1 '}
                {t(getIngredientUnitString(tempIngredients?.translationUnit))}
              </p>
            </div>
          )}

        </div>
        {!selectedIngredients.length ? null : (
          <div style={{ display: 'flex', marginTop: '30px', background: WARNING_LIGHT, padding: '10px', borderRadius: "10px" }}>
            <div style={{ width: '60%' }}>
              <h3 style={{marginBottom:'40px'}}>{t("HEADING_ADDED_INGREDIENTS")}</h3>

              <div>
                {
                  selectedIngredients.map((ingredient) => (
                    <div key={ingredient.id} style={{ display: "flex", marginTop: "12px", gap: isMobile?"0px":"16px" }}>
                      {editMode[ingredient.id] ? (
                          <div style={{ display: "flex", marginTop: "3px", gap: isMobile?"0px":"0px" }}><input
                              type="number"
                              value={editAmount[ingredient.id]}
                              onChange={(e) => setEditAmount({ ...editAmount, [ingredient.id]: parseFloat(e.target.value) })}
                              style={{ width: "46px" }}
                          />

                            <Iconify icon={"ei:check"} onClick={() => handleSaveClick(ingredient.id)} />
                            <Iconify icon={"ei:close"} onClick={() => handleCancelClick(ingredient.id)} />
                          </div>
                      ) : (
                      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                      <p style={{ minWidth: "28px", margin: 0, paddingTop: '3px' }} onClick={() => handleEditClick(ingredient.id, ingredient.quantity)}>{ingredient.quantity}</p>
                        )}
                      {!editMode[ingredient.id] ? (
                      <p style={{ minWidth: "42px", margin: 0, paddingTop: '3px' }}>{t(getIngredientUnitString(ingredient.ingredientUnit))}</p>
                        ) : null}
                      <p style={{ minWidth: isMobile?"120px":"250px",maxWidth: isMobile?"120px":"250px", margin: 0, paddingTop: '3px' }}>{localStorageGetItem('i18nextLng', defaultLang.value) === 'en' ? ingredient.name_en : ingredient.name}</p>
                       {/* add 3 icons from iconify with on click do minus plus and delete */}
                      <div style={{ display: "flex", alignItems: "left", justifyContent: "left", marginTop: "5px", gap: isMobile?"5px":"16px" }}>
                       <Iconify icon="ei:minus" onClick={() => {editIngredient(ingredient.id,ingredient.quantity-ingredient.steps)}}/>
                       <Iconify icon="ei:plus" onClick={() => {editIngredient(ingredient.id,ingredient.quantity+ingredient.steps)}}/>
                       <Iconify icon="ei:trash" color={"red"} onClick={() => {
                         removeIngredient(ingredient.id);
                       }}/>
                      </div>

                    </div>
                  ))
                }
              </div>
            </div>
            <div style={{ width: '40%' }}>
              <AppCurrentDownload
                title="Calories Calculation"
                chart={{
                  colors: ["#6BC59D", "#F8C407", "#B24C85"],
                  series: [
                    { label: t("LABEL_PROTEIN"), value: statistics?.protein || 0 },
                    { label: t("LABEL_CARBS"), value: statistics?.carbs || 0 },
                    { label: t("LABEL_FAT"), value: statistics?.fat || 0 },
                  ],
                }}
                total={statistics?.totalCal || 0}
              />
            </div>

            <div style={{ display: 'none' }}>
              {
                statistics && (
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "12px", gap: "16px" }}>
                    <p>{statistics.total}</p>
                    <p>{statistics.EW} protein</p>
                    <p>{statistics.KH} carbohydrates</p>
                    <p>{statistics.F} grams of fat</p>
                  </div>
                )
              }
            </div>

          </div>
        )}

      </div>
    )
  }
  const handleDropSingleFile = useCallback((acceptedFiles: File[]) => {
    const newFile = acceptedFiles[0];
    if (newFile) {
      setSelectedFileFile(
        Object.assign(newFile, {
          preview: URL.createObjectURL(newFile),
        })
      );
    }
  }, []);
  const getDisabledText = () => {
    if (!selectedIngredients.length) {
      return t("ALERT_ADD_ONE_INGREDIENT");
    }
    if (!selectedFile) {
      return t("ALERT_ADD_IMAGE");
    }
    return "";
  }
  const [mealPrep, setmealPrep] = useState<boolean>(selectedRecipe?.mealPrep === 1);
  const [notCook, setnotCook] = useState<boolean>(selectedRecipe?.notCook === 1);

  interface DietType {
    label: string;
    value: string;
  }

  const DietTypeOptionsLocal: DietType[] = [
    {
      label: t("BUTTON_NUTRITION_OMNIVORE"),
      value: "normalDiet",
    },
    {
      label: t("BUTTON_NUTRITION_PESCETARIAN"),
      value: "pescetarian",
    },
    {
      label: t("BUTTON_NUTRITION_VEGETARIAN"),
      value: "vegetarian",
    },
    {
      label: t("BUTTON_NUTRITION_VEGAN"),
      value: "vegan",
    },
    {
      label: t("BUTTON_NUTRITION_PALEO"),
      value: "paleo",
    },
    {
      label: t("BUTTON_NUTRITION_LOW_CARB"),
      value: "low_carb",
    },
    {
      label: t("BUTTON_NUTRITION_KETO"),
      value: "keto",
    }
  ];

  const [value, setValue] = React.useState(0);
  const [valuePrep, setValuePrep] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const handleChangePrep = (event: React.SyntheticEvent, newValue: number) => {
    setValuePrep(newValue);
  };




  return (
    <FormProvider methods={methods} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid xs={12} md={12}>
          <Card sx={{ p: 3 }}>
            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
              style={{ background: theme.palette.mode === 'light' ? '#F9F9FA' : theme.palette.grey[700] }}
            >

              <div style={{ margin: '15px' }}>
                <h3>{t("RECIPE_DETAILS")}</h3>
                <Box sx={{ width: '100%' }}>
                  <Box style={{ height: '35px' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" style={{ height: '35px' }}>
                      <Tab style={{ fontSize: '10px', padding: 0, margin: 0 }} label={t("GERMAN")} {...a11yProps(0)} />
                      <Tab style={{ fontSize: '10px', padding: 0, margin: 0 }} label={t("ENGLISH")} {...a11yProps(1)} />
                    </Tabs>
                  </Box>
                  <CustomTabPanel value={value} index={0}>
                    <RHFTextField name="title" label={t("RECIPE_TITLE")} style={{ marginBottom: '15px', background: theme.palette.mode === 'light' ? 'white' : theme.palette.grey[600] }} />
                  </CustomTabPanel>
                  <CustomTabPanel value={value} index={1}>
                    <RHFTextField name="title_en" label={t("RECIPE_TITLE_EN")} style={{ marginBottom: '15px', background: theme.palette.mode === 'light' ? 'white' : theme.palette.grey[600] }} />
                  </CustomTabPanel>
                </Box>
                <RHFSelect name="mealType" label={t("MEALTYPE")} InputLabelProps={{ shrink: true }} style={{ marginBottom: '15px', background: theme.palette.mode === 'light' ? 'white' : theme.palette.grey[600] }}>
                  {MealTypeOptions.map(type => (
                    <MenuItem key={type.value} value={type.value}>
                      {t(type.label)}
                    </MenuItem>
                  ))}
                </RHFSelect>
                <RHFSelect name="prepTime" label={t("PREP_TIME")} style={{ marginBottom: '15px', background: theme.palette.mode === 'light' ? 'white' : theme.palette.grey[600] }}>
                  {PreparationTimeOptions.map(time => (
                    <MenuItem key={time} value={time}>
                      {time} min
                    </MenuItem>
                  ))}
                </RHFSelect>
                <RHFMultiSelect
                  checkbox
                  name="advance"
                  label={t("DIET")}
                  options={DietTypeOptionsLocal}
                  sx={{ width: '100%' }}
                  style={{ marginBottom: '15px', width: '100%', background: theme.palette.mode === 'light' ? 'white' : theme.palette.grey[600] }}
                />

                <RHFSelect name="difficulty" label={t("DIFFICULTY")} InputLabelProps={{ shrink: true }} style={{ marginBottom: '15px', background: theme.palette.mode === 'light' ? 'white' : theme.palette.grey[600] }}>
                  {DifficultyLevelOptions.map(level => (
                    <MenuItem key={level.value} value={level.value}>
                      {t(level.label)}
                    </MenuItem>
                  ))}
                </RHFSelect>

                <RHFSelect name="budget" label={t("LABEL_STEP_BUDGET")} InputLabelProps={{ shrink: true }} style={{ marginBottom: '15px', background: theme.palette.mode === 'light' ? 'white' : theme.palette.grey[600] }}>
                  {BudgetOptions.map(budget => (
                    <MenuItem key={budget.value} value={budget.value}>
                      {t(budget.label)}
                    </MenuItem>
                  ))}
                </RHFSelect>



              </div>


              <div style={{ margin: '15px' }}>
                <h3>{t("RECIPE_IMAGE")}</h3>
                <Upload accept={{ 'image/jpeg': [], 'image/jpg': [], 'image/png': []}}  file={selectedFile} onDrop={handleDropSingleFile} onDelete={() => setSelectedFileFile(null)} />
              </div>
              <Stack spacing={3} alignItems="flex-start" sx={{ mt: -5, mb: 5, ml: 2 }}>
                <FormControl>
                  <div>
                    <Checkbox checked={notCook} onChange={() => {
                      setnotCook(!notCook);
                    }} />
                    <FormLabel>{t("ADD_RECIPE_NOT_COOK")}</FormLabel>
                  </div>
                </FormControl>
              </Stack>
              <Stack spacing={3} alignItems="flex-start" sx={{ mt: -5, mb: 5 }}>
                <FormControl>
                  <div>
                    <Checkbox checked={mealPrep} onChange={() => {
                      setmealPrep(!mealPrep);
                    }} />
                    <FormLabel>{t("ADD_RECIPE_MEAL_PREP")}</FormLabel>
                  </div>
                </FormControl>
              </Stack>




            </Box>
            {renderIngredientsSelection()}
            <div style={{ marginTop: "25px", background: theme.palette.mode === 'light' ? '#F9F9FA' : theme.palette.grey[700], padding: '20px', borderRadius: '10px' }}>
              <h3>{t("HEADING_PREPARATION")}</h3>
              <Box sx={{ width: '100%' }}>
                <Box style={{ height: '35px' }}>
                  <Tabs value={valuePrep} onChange={handleChangePrep} aria-label="" style={{ height: '35px' }}>
                    <Tab style={{ fontSize: '10px', padding: 0, margin: 0 }} label={t("GERMAN")} {...a11yProps(0)} />
                    <Tab style={{ fontSize: '10px', padding: 0, margin: 0 }} label={t("ENGLISH")} {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <CustomTabPanel value={valuePrep} index={0}>
                  <RHFTextField name="preparation" fullWidth multiline rows={5} placeholder={t("PREP_STEPS")} style={{ width: "100%", background: theme.palette.mode === 'light' ? 'white' : theme.palette.grey[600] }} />
                </CustomTabPanel>
                <CustomTabPanel value={valuePrep} index={1}>
                  <RHFTextField name="preparation_en" fullWidth multiline rows={5} placeholder={t("PREP_STEPS_EN")} style={{ width: "100%", background: theme.palette.mode === 'light' ? 'white' : theme.palette.grey[600] }} />
                </CustomTabPanel>
              </Box>
            </div>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <p style={{ color: "red" }}>{getDisabledText()}</p>
              <LoadingButton disabled={!selectedIngredients.length || !selectedFile} color='primary' type="submit" variant="contained" loading={isSubmitting}>
                {!selectedRecipe ? t("BUTTON_ADD_RECIPE") : t('UPDATE_RECIPE')}
              </LoadingButton>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </FormProvider>
  );
}
