const NutritionValues = [
    {
        name: "LABEL_SODIUM",
        tooltip: 'TOOLTIP_SODIUM',
        key: 'natrium',
        maxValue: 1500,
        maxValueFemale19to25:1500,
        maxValueFemale25to51:1500,
        maxValueFemale51to65:1500,
        maxValueFemale65plus:1500,
        maxValueMale19to25:1500,
        maxValueMale25to51:1500,
        maxValueMale51to65:1500,
        maxValueMale65plus:1500,
        maxValueChildren1to4: 400,
        maxValueChildren4to7: 500,
        maxValueChildren7to10: 750,
        maxValueChildren10to13: 1100,
        maxValueChildren13to15Male: 1400,
        maxValueChildren13to15Female: 1400,
        maxValueChildren15to19Male: 1500,
        maxValueChildren15to19Female: 1500,
        maxValuePregnancy: 1500,
        maxValueBreastfeeding: 1500,
        OverdosePercentage:500,
        unit: 'mg',
        isMineral: true,
    },
    {
        name: "LABEL_POTASSIUM",
        tooltip: 'TOOLTIP_POTASSIUM',
        key: 'kalium',
        maxValue: 4000, // Allgemeiner Maximalwert für Erwachsene (inkl. Männer und Frauen)
        maxValueFemale19to25: 4000,
        maxValueFemale25to51: 4000,
        maxValueFemale51to65: 4000,
        maxValueFemale65plus: 4000,
        maxValueMale19to25: 4000,
        maxValueMale25to51: 4000,
        maxValueMale51to65: 4000,
        maxValueMale65plus: 4000,
        maxValueChildren1to4: 1100,
        maxValueChildren4to7: 1300,
        maxValueChildren7to10: 2000,
        maxValueChildren10to13: 2900,
        maxValueChildren13to15Male: 3600,
        maxValueChildren13to15Female: 3600,
        maxValueChildren15to19Male: 4000,
        maxValueChildren15to19Female: 4000,
        maxValuePregnancy: 4000,
        maxValueBreastfeeding: 4400,
        OverdosePercentage: 500, // Beibehalten wie zuvor
        unit: 'mg',
        isMineral: true,
    },
    {
        name: "LABEL_MAGNESIUM",
        tooltip: 'TOOLTIP_MAGNESIUM',
        key: 'magnesium',
        maxValue: 350, // Allgemeiner Maximalwert für Erwachsene (Männer)
        maxValueFemale19to25: 300,
        maxValueFemale25to51: 300,
        maxValueFemale51to65: 300,
        maxValueFemale65plus: 300,
        maxValueMale19to25: 350,
        maxValueMale25to51: 350,
        maxValueMale51to65: 350,
        maxValueMale65plus: 350,
        maxValueChildren1to4: 170,
        maxValueChildren4to7: 190,
        maxValueChildren7to10: 240,
        maxValueChildren10to13: 260, // m: 260, w: 230 (Durchschnittlich betrachtet)
        maxValueChildren13to15Male: 280,
        maxValueChildren13to15Female: 240,
        maxValueChildren15to19Male: 330,
        maxValueChildren15to19Female: 260,
        maxValuePregnancy: 300,
        maxValueBreastfeeding: 300,
        OverdosePercentage: 500, // Wie angegeben
        unit: 'mg',
        isMineral: true,
    },
    {
        name: "LABEL_CHLORIDE",
        tooltip: 'TOOLTIP_CHLORIDE',
        key: 'chlorid',
        maxValue: 2300, // Allgemeiner Maximalwert für Erwachsene (inkl. Männer und Frauen)
        maxValueFemale19to25: 2300,
        maxValueFemale25to51: 2300,
        maxValueFemale51to65: 2300,
        maxValueFemale65plus: 2300,
        maxValueMale19to25: 2300,
        maxValueMale25to51: 2300,
        maxValueMale51to65: 2300,
        maxValueMale65plus: 2300,
        maxValueChildren1to4: 600,
        maxValueChildren4to7: 750,
        maxValueChildren7to10: 1150,
        maxValueChildren10to13: 1700,
        maxValueChildren13to15Male: 2150,
        maxValueChildren13to15Female: 2150,
        maxValueChildren15to19Male: 2300,
        maxValueChildren15to19Female: 2300,
        maxValuePregnancy: 2300,
        maxValueBreastfeeding: 2300,
        OverdosePercentage: 500, // Beibehalten wie zuvor
        unit: 'mg',
        isMineral: true,
    },
    {
        name: "LABEL_CALCIUM",
        tooltip: 'TOOLTIP_CALCIUM',
        key: 'calcium',
        maxValue: 1000, // Allgemeiner Maximalwert für Erwachsene
        maxValueFemale19to25: 1000,
        maxValueFemale25to51: 1000,
        maxValueFemale51to65: 1000,
        maxValueFemale65plus: 1000,
        maxValueMale19to25: 1000,
        maxValueMale25to51: 1000,
        maxValueMale51to65: 1000,
        maxValueMale65plus: 1000,
        maxValueChildren1to4: 600,
        maxValueChildren4to7: 750,
        maxValueChildren7to10: 900,
        maxValueChildren10to13: 1100,
        maxValueChildren13to15Male: 1200,
        maxValueChildren13to15Female: 1200,
        maxValueChildren15to19Male: 1200,
        maxValueChildren15to19Female: 1200,
        maxValuePregnancy: 1000, // Für Schwangere ab 19 Jahren
        maxValueBreastfeeding: 1000, // Für Stillende ab 19 Jahren
        maxValuePregnancyUnder19: 1200, // Für Schwangere unter 19 Jahren
        maxValueBreastfeedingUnder19: 1200, // Für Stillende unter 19 Jahren
        OverdosePercentage: 500, // Beibehalten wie zuvor
        unit: 'mg',
        isMineral: true,
    },
    {
        name: "LABEL_PHOSPHORUS",
        tooltip: 'TOOLTIP_PHOSPHORUS',
        key: 'phosphorus',
        maxValue: 550, // Allgemeiner Maximalwert für Erwachsene
        maxValueFemale19to25: 550,
        maxValueFemale25to51: 550,
        maxValueFemale51to65: 550,
        maxValueFemale65plus: 550,
        maxValueMale19to25: 550,
        maxValueMale25to51: 550,
        maxValueMale51to65: 550,
        maxValueMale65plus: 550,
        maxValueChildren1to4: 330,
        maxValueChildren4to7: 410,
        maxValueChildren7to10: 500,
        maxValueChildren10to13: 610,
        maxValueChildren13to15Male: 660,
        maxValueChildren13to15Female: 660,
        maxValueChildren15to19Male: 660,
        maxValueChildren15to19Female: 660,
        maxValuePregnancy: 550, // Für Schwangere ab 19 Jahren
        maxValueBreastfeeding: 550, // Für Stillende ab 19 Jahren
        maxValuePregnancyUnder19: 660, // Für Schwangere unter 19 Jahren
        maxValueBreastfeedingUnder19: 660, // Für Stillende unter 19 Jahren
        OverdosePercentage: 500, // Beibehalten wie zuvor
        unit: 'mg',
        isMineral: true,
    },
    {
        name: "LABEL_IRON",
        tooltip: 'TOOLTIP_IRON',
        key: 'iron',
        maxValue: 12500,
        maxValueMale19to25: 11000, // 11 mg in µg
        maxValueMale25to51: 11000,
        maxValueMale51to65: 11000,
        maxValueMale65plus: 11000,
        maxValueFemale19to25: 16000, // 16 mg in µg für prämenopausale Frauen
        maxValueFemale25to51: 16000, // prämenopausal
        maxValueFemale51to65: 14000, // postmenopausal
        maxValueFemale65plus: 14000,
        maxValueChildren1to4: 7000, // 7 mg in µg
        maxValueChildren4to7: 7000,
        maxValueChildren7to10: 10000, // 10 mg in µg
        maxValueChildren10to13: 14000, // 14 mg in µg
        maxValueChildren13to15Male: 11000, // 11 mg in µg
        maxValueChildren13to15Female: 16000, // 16 mg in µg
        maxValueChildren15to19Male: 11000, // 11 mg in µg
        maxValueChildren15to19Female: 16000, // 16 mg in µg
        maxValuePregnancy: 27000, // 27 mg in µg
        maxValueBreastfeeding: 16000, // 16 mg in µg
        OverdosePercentage: 500, // Beibehalten wie zuvor
        unit: 'µg',
        isTrace: true,
    },
    {
        name: "LABEL_IODINE",
        tooltip: 'TOOLTIP_IODINE',
        key: 'iodine',
        maxValue: 200,
        maxValueMale19to25: 200, // 200 µg/Tag
        maxValueMale25to51: 200,
        maxValueMale51to65: 180, // 180 µg/Tag
        maxValueMale65plus: 180,
        maxValueFemale19to25: 200,
        maxValueFemale25to51: 200,
        maxValueFemale51to65: 180,
        maxValueFemale65plus: 180,
        maxValueChildren1to4: 100, // 100 µg/Tag
        maxValueChildren4to7: 120, // 120 µg/Tag
        maxValueChildren7to10: 140, // 140 µg/Tag
        maxValueChildren10to13: 180, // 180 µg/Tag
        maxValueChildren13to15Male: 200,
        maxValueChildren13to15Female: 200,
        maxValueChildren15to19Male: 200,
        maxValueChildren15to19Female: 200,
        maxValuePregnancy: 230, // Für Schwangere: 230 µg/Tag
        maxValueBreastfeeding: 260, // Für Stillende: 260 µg/Tag
        OverdosePercentage: 500, // Beibehalten wie zuvor
        unit: 'µg',
        isTrace: true,
    },
    {
        name: "LABEL_FLUORINE",
        tooltip: 'TOOLTIP_FLUORINE',
        key: 'flour',
        maxValue: 3800, // Maximalwert bleibt unberührt
        maxValueMale19to25: 3500, // 3,5 mg/Tag in µg
        maxValueMale25to51: 3500,
        maxValueMale51to65: 3500,
        maxValueMale65plus: 3500,
        maxValueFemale19to25: 3000, // 3,0 mg/Tag in µg
        maxValueFemale25to51: 3000,
        maxValueFemale51to65: 3000,
        maxValueFemale65plus: 3000,
        maxValueChildren1to4: 700, // 0,7 mg/Tag in µg
        maxValueChildren4to7: 1000, // 1,0 mg/Tag in µg
        maxValueChildren7to10: 1500, // 1,5 mg/Tag in µg
        maxValueChildren10to13: 2100, // 2,1 mg/Tag in µg
        maxValueChildren13to15Male: 2800, // 2,8 mg/Tag in µg
        maxValueChildren13to15Female: 2700, // 2,7 mg/Tag in µg
        maxValueChildren15to19Male: 3500, // 3,5 mg/Tag in µg
        maxValueChildren15to19Female: 3000, // 3,0 mg/Tag in µg
        maxValuePregnancy: 3000, // Für Schwangere: 3,0 mg/Tag in µg
        maxValueBreastfeeding: 3000, // Für Stillende: 3,0 mg/Tag in µg
        OverdosePercentage: 500, // Beibehalten wie zuvor
        unit: 'µg',
        isTrace: true,
    },
    {
        name: "LABEL_ZINC",
        tooltip: 'TOOLTIP_ZINC',
        key: 'zink',
        maxValue: 8500, // Maximalwert bleibt unberührt
        maxValueMale19to25: 14000, // 14 mg in µg für mittlere Phytatzufuhr
        maxValueMale25to51: 14000,
        maxValueMale51to65: 14000,
        maxValueMale65plus: 14000,
        maxValueFemale19to25: 8000, // 8 mg in µg für mittlere Phytatzufuhr
        maxValueFemale25to51: 8000,
        maxValueFemale51to65: 8000,
        maxValueFemale65plus: 8000,
        maxValueChildren1to4: 3000, // 3 mg in µg
        maxValueChildren4to7: 4000, // 4 mg in µg
        maxValueChildren7to10: 6000, // 6 mg in µg
        maxValueChildren10to13Male: 9000, // 9 mg in µg
        maxValueChildren10to13Female: 8000, // 8 mg in µg
        maxValueChildren13to15Male: 12000, // 12 mg in µg
        maxValueChildren13to15Female: 10000, // 10 mg in µg
        maxValueChildren15to19Male: 14000, // 14 mg in µg
        maxValueChildren15to19Female: 11000, // 11 mg in µg
        maxValuePregnancy: 10000, // average
        maxValuePregnancy1stTrimester: 9000, // 9 mg in µg
        maxValuePregnancy2nd3rdTrimester: 11000, // 11 mg in µg
        maxValueBreastfeeding: 13000, // 13 mg in µg
        OverdosePercentage: 500, // Beibehalten wie zuvor
        unit: 'µg',
        isTrace: true,
    },
    {
        name: "LABEL_MANGANESE",
        tooltip: 'TOOLTIP_MANGANESE',
        key: 'mangan',
        maxValue: 3500, // Maximalwert bleibt unverändert
        maxValueMale19to25: 5000, // 5 mg in µg
        maxValueMale25to51: 5000,
        maxValueMale51to65: 5000,
        maxValueMale65plus: 5000,
        maxValueFemale19to25: 5000, // 5 mg in µg
        maxValueFemale25to51: 5000,
        maxValueFemale51to65: 5000,
        maxValueFemale65plus: 5000,
        maxValueChildren1to4: 1500, // 1,5 mg in µg
        maxValueChildren4to7: 2000, // 2,0 mg in µg
        maxValueChildren7to10: 3000, // 3,0 mg in µg
        maxValueChildren10to13: 5000, // 5,0 mg in µg
        maxValueChildren13to15Male: 5000,
        maxValueChildren13to15Female: 5000,
        maxValueChildren15to19Male: 5000,
        maxValueChildren15to19Female: 5000,
        maxValuePregnancy: 5000, // Durchschnittswert
        maxValueBreastfeeding: 5000, // Durchschnittswert
        OverdosePercentage: 500, // Beibehalten wie zuvor
        unit: 'µg',
        isTrace: true,
    },
    //   {
    //     name:'Selenium',
    //     key:'selenium',
    //     maxValue:70
    //   },
    {
        name: "LABEL_VITAMIN_C",
        tooltip: 'TOOLTIP_VITAMIN_C',
        key: 'vitC',
        maxValue: 100000, // Maximalwert bleibt unberührt
        maxValueMale19to25: 110000, // 110 mg in µg
        maxValueMale25to51: 110000,
        maxValueMale51to65: 110000,
        maxValueMale65plus: 110000,
        maxValueFemale19to25: 95000, // 95 mg in µg
        maxValueFemale25to51: 95000,
        maxValueFemale51to65: 95000,
        maxValueFemale65plus: 95000,
        maxValueChildren1to4: 20000, // 20 mg in µg
        maxValueChildren4to7: 30000, // 30 mg in µg
        maxValueChildren7to10: 45000, // 45 mg in µg
        maxValueChildren10to13: 65000, // 65 mg in µg
        maxValueChildren13to15Male: 85000,
        maxValueChildren13to15Female: 85000,
        maxValueChildren15to19Male: 105000, // 105 mg in µg
        maxValueChildren15to19Female: 90000, // 90 mg in µg
        unit: 'µg',
        isVitamin: true,
    },
    {
        name: "LABEL_VITAMIN_B1",
        tooltip: 'TOOLTIP_VITAMIN_B1',
        key: 'vitB1',
        maxValue: 1100, // Maximalwert bleibt unberührt
        maxValueMale19to25: 1300, // 1,3 mg in µg
        maxValueMale25to51: 1200, // 1,2 mg in µg
        maxValueMale51to65: 1200,
        maxValueMale65plus: 1100, // 1,1 mg in µg
        maxValueFemale19to25: 1000, // 1,0 mg in µg
        maxValueFemale25to51: 1000,
        maxValueFemale51to65: 1000,
        maxValueFemale65plus: 1000,
        maxValueChildren1to4: 600, // Durchschnitt: 0,6 mg in µg
        maxValueChildren4to7: 700, // Durchschnitt: 0,7 mg in µg
        maxValueChildren7to10: 850, // Durchschnitt: (0,9 + 0,8) / 2 = 0,85 mg in µg
        maxValueChildren10to13: 950, // Durchschnitt: (1,0 + 0,9) / 2 = 0,95 mg in µg
        maxValueChildren13to15Male: 1100,
        maxValueChildren13to15Female: 1100,
        maxValueChildren15to19Male: 1250,
        maxValueChildren15to19Female: 1250,
        maxValuePregnancy: 1250, // average
        maxValuePregnancyTrimester2: 1200, // 1,2 mg in µg
        maxValuePregnancyTrimester3: 1300, // 1,3 mg in µg
        maxValueBreastfeeding: 1300, // 1,3 mg in µg
        OverdosePercentage: 500, // Beibehalten wie zuvor
        unit: 'µg',
        isVitamin: true,
    },
    {
        name: "LABEL_VITAMIN_B2",
        tooltip: 'TOOLTIP_VITAMIN_B2',
        key: 'vitB2',
        maxValue: 1200, // Maximalwert bleibt unberührt
        maxValueMale19to25: 1400, // 1,4 mg in µg
        maxValueMale25to51: 1400,
        maxValueMale51to65: 1300, // 1,3 mg in µg
        maxValueMale65plus: 1300,
        maxValueFemale19to25: 1100, // 1,1 mg in µg
        maxValueFemale25to51: 1100,
        maxValueFemale51to65: 1000, // 1,0 mg in µg
        maxValueFemale65plus: 1000,
        maxValueChildren1to4: 700, // 0,7 mg in µg
        maxValueChildren4to7: 800, // 0,8 mg in µg
        maxValueChildren7to10: 950, // Durchschnitt: (1,0 + 0,9) / 2 = 0,95 mg in µg
        maxValueChildren10to13: 1050, // Durchschnitt: (1,1 + 1,0) / 2 = 1,05 mg in µg
        maxValueChildren13to15Male: 1400,
        maxValueChildren13to15Female: 1100,
        maxValueChildren15to19Male: 1600,
        maxValueChildren15to19Female: 1200,
        maxValuePregnancy: 1350, // average
        maxValuePregnancyTrimester2: 1300, // 1,3 mg in µg
        maxValuePregnancyTrimester3: 1400, // 1,4 mg in µg
        maxValueBreastfeeding: 1400, // 1,4 mg in µg
        OverdosePercentage: 500, // Beibehalten wie zuvor
        unit: 'µg',
        isVitamin: true,
    },
    {
        name: "LABEL_VITAMIN_B3",
        tooltip: 'TOOLTIP_VITAMIN_B3',
        key: 'vitB3',
        maxValue: 17000, // Maximalwert bleibt unberührt
        maxValueMale19to25: 16000, // 16 mg in µg
        maxValueMale25to51: 15000, // 15 mg in µg
        maxValueMale51to65: 15000,
        maxValueMale65plus: 14000, // 14 mg in µg
        maxValueFemale19to25: 13000, // 13 mg in µg
        maxValueFemale25to51: 12000, // 12 mg in µg
        maxValueFemale51to65: 11000, // 11 mg in µg
        maxValueFemale65plus: 11000,
        maxValueChildren1to4: 8000, // 8 mg in µg
        maxValueChildren4to7: 9000, // 9 mg in µg
        maxValueChildren7to10: 10500, // Durchschnitt: (11 + 10) / 2 = 10,5 mg in µg
        maxValueChildren10to13: 12000, // Durchschnitt: (13 + 11) / 2 = 12 mg in µg
        maxValueChildren13to15Male: 15000,
        maxValueChildren13to15Female: 13000,
        maxValueChildren15to19Male: 17000,
        maxValueChildren15to19Female: 13000,
        maxValuePregnancy: 15000, // Durchschnitt: (14 + 16) / 2 = 15 mg in µg
        maxValuePregnancyTrimester2: 14000, // 14 mg in µg
        maxValuePregnancyTrimester3: 16000, // 16 mg in µg
        maxValueBreastfeeding: 16000, // 16 mg in µg
        OverdosePercentage: 500, // Beibehalten wie zuvor
        unit: 'µg',
        isVitamin: true,
    },
    {
        name: "LABEL_VITAMIN_B5",
        tooltip: 'TOOLTIP_VITAMIN_B5',
        key: 'vitB5',
        maxValue: 6000, // Maximalwert bleibt unberührt
        maxValueMale19to25: 5000, // 5 mg in µg
        maxValueMale25to51: 5000, // 5 mg in µg
        maxValueMale51to65: 5000,
        maxValueMale65plus: 5000,
        maxValueFemale19to25: 5000, // 5 mg in µg
        maxValueFemale25to51: 5000,
        maxValueFemale51to65: 5000,
        maxValueFemale65plus: 5000,
        maxValueChildren1to4: 4000, // 4 mg in µg
        maxValueChildren4to7: 4000, // 4 mg in µg
        maxValueChildren7to10: 4000, // 4 mg in µg
        maxValueChildren10to13: 5000, // 5 mg in µg
        maxValueChildren13to15Male: 5000,
        maxValueChildren13to15Female: 5000,
        maxValueChildren15to19Male: 5000,
        maxValueChildren15to19Female: 5000,
        maxValuePregnancy: 5000, // 5 mg in µg
        maxValueBreastfeeding: 7000, // 7 mg in µg
        OverdosePercentage: 500, // Beibehalten wie zuvor
        unit: 'µg',
        isVitamin: true,
    },
    {
        name: "LABEL_VITAMIN_B6",
        tooltip: 'TOOLTIP_VITAMIN_B6',
        key: 'vitB6',
        maxValue: 2000, // Maximalwert bleibt unberührt
        maxValueMale19to25: 1600, // 1,6 mg in µg
        maxValueMale25to51: 1600, // 1,6 mg in µg
        maxValueMale51to65: 1600,
        maxValueMale65plus: 1600,
        maxValueFemale19to25: 1400, // 1,4 mg in µg
        maxValueFemale25to51: 1400,
        maxValueFemale51to65: 1400,
        maxValueFemale65plus: 1400,
        maxValueChildren1to4: 600, // 0,6 mg in µg
        maxValueChildren4to7: 700, // 0,7 mg in µg
        maxValueChildren7to10: 1000, // 1,0 mg in µg
        maxValueChildren10to13: 1200, // 1,2 mg in µg
        maxValueChildren13to15Male: 1500,
        maxValueChildren13to15Female: 1400,
        maxValueChildren15to19Male: 1600,
        maxValueChildren15to19Female: 1400,
        maxValuePregnancy: 1800, // 1,5 mg in µg
        maxValuePregnancyTrimester1: 1500, // 1,5 mg in µg
        maxValuePregnancyTrimester2: 1800, // 1,8 mg in µg
        maxValuePregnancyTrimester3: 1800, // 1,8 mg in µg
        maxValueBreastfeeding: 1600, // 1,6 mg in µg
        OverdosePercentage: 500, // Beibehalten wie zuvor
        unit: 'µg',
        isVitamin: true,
    },
    {
        name: "LABEL_VITAMIN_B7",
        tooltip: 'TOOLTIP_VITAMIN_B7',
        key: 'vitB7',
        maxValue: 40, // Maximalwert bleibt unberührt
        maxValueMale19to25: 40, // 40 µg/Tag
        maxValueMale25to51: 40, // 40 µg/Tag
        maxValueMale51to65: 40,
        maxValueMale65plus: 40,
        maxValueFemale19to25: 40, // 40 µg/Tag
        maxValueFemale25to51: 40,
        maxValueFemale51to65: 40,
        maxValueFemale65plus: 40,
        maxValueChildren1to4: 20, // 20 µg/Tag
        maxValueChildren4to7: 25, // 25 µg/Tag
        maxValueChildren7to10: 25, // 25 µg/Tag
        maxValueChildren10to13: 35, // 35 µg/Tag
        maxValueChildren13to15Male: 35,
        maxValueChildren13to15Female: 35,
        maxValueChildren15to19Male: 40,
        maxValueChildren15to19Female: 40,
        maxValuePregnancy: 40, // 40 µg/Tag
        maxValueBreastfeeding: 45, // 45 µg/Tag
        OverdosePercentage: 500, // Beibehalten wie zuvor
        unit: 'µg',
        isVitamin: true,
    },
    {
        name: "LABEL_VITAMIN_B9",
        tooltip: 'TOOLTIP_VITAMIN_B9',
        key: 'vitB9',
        maxValue: 300, // Maximalwert bleibt unberührt
        maxValueMale19to25: 300, // 300 µg/Tag
        maxValueMale25to51: 300, // 300 µg/Tag
        maxValueMale51to65: 300,
        maxValueMale65plus: 300,
        maxValueFemale19to25: 300, // 300 µg/Tag
        maxValueFemale25to51: 300,
        maxValueFemale51to65: 300,
        maxValueFemale65plus: 300,
        maxValueChildren1to4: 120, // 120 µg/Tag
        maxValueChildren4to7: 140, // 140 µg/Tag
        maxValueChildren7to10: 180, // 180 µg/Tag
        maxValueChildren10to13: 240, // 240 µg/Tag
        maxValueChildren13to15Male: 300,
        maxValueChildren13to15Female: 300,
        maxValueChildren15to19Male: 300,
        maxValueChildren15to19Female: 300,
        maxValuePregnancy: 550, // 550 µg/Tag
        maxValueBreastfeeding: 450, // 450 µg/Tag
        OverdosePercentage: 500, // Beibehalten wie zuvor
        unit: 'µg',
        isVitamin: true,
    },
    {
        name: "LABEL_VITAMIN_B12",
        tooltip: 'TOOLTIP_VITAMIN_B12',
        key: 'vitB12',
        maxValue: 4, // Maximalwert bleibt unberührt
        maxValueMale19to25: 4, // 4 µg/Tag
        maxValueMale25to51: 4, // 4 µg/Tag
        maxValueMale51to65: 4,
        maxValueMale65plus: 4,
        maxValueFemale19to25: 4, // 4 µg/Tag
        maxValueFemale25to51: 4,
        maxValueFemale51to65: 4,
        maxValueFemale65plus: 4,
        maxValueChildren1to4: 1.5, // 1,5 µg/Tag
        maxValueChildren4to7: 2.0, // 2,0 µg/Tag
        maxValueChildren7to10: 2.5, // 2,5 µg/Tag
        maxValueChildren10to13: 3.5, // 3,5 µg/Tag
        maxValueChildren13to15Male: 4.0,
        maxValueChildren13to15Female: 4.0,
        maxValueChildren15to19Male: 4.0,
        maxValueChildren15to19Female: 4.0,
        maxValuePregnancy: 4.5, // 4,5 µg/Tag
        maxValueBreastfeeding: 5.5, // 5,5 µg/Tag
        OverdosePercentage: 500, // Beibehalten wie zuvor
        unit: 'µg',
        isVitamin: true,
    },
    {
        name: "LABEL_VITAMIN_A",
        tooltip: 'TOOLTIP_VITAMIN_A',
        key: 'vitA',
        maxValue: 1000, // Maximalwert bleibt unberührt
        maxValueMale19to25: 850, // 850 µg/Tag
        maxValueMale25to51: 850, // 850 µg/Tag
        maxValueMale51to65: 850,
        maxValueMale65plus: 800, // 800 µg/Tag
        maxValueFemale19to25: 700, // 700 µg/Tag
        maxValueFemale25to51: 700,
        maxValueFemale51to65: 700,
        maxValueFemale65plus: 700,
        maxValueChildren1to4: 300, // 300 µg/Tag
        maxValueChildren4to7: 350, // 350 µg/Tag
        maxValueChildren7to10: 450, // 450 µg/Tag
        maxValueChildren10to13: 650, // Durchschnitt: (600 + 600) / 2 = 650 µg/Tag
        maxValueChildren13to15Male: 800,
        maxValueChildren13to15Female: 700,
        maxValueChildren15to19Male: 950,
        maxValueChildren15to19Female: 800,
        maxValuePregnancy: 800, // 800 µg/Tag
        maxValueBreastfeeding: 1300, // 1300 µg/Tag
        OverdosePercentage: 500, // Beibehalten wie zuvor
        unit: 'µg',
        isVitamin: true,
    },
    {
        name: "LABEL_VITAMIN_D",
        tooltip: 'TOOLTIP_VITAMIN_D',
        key: 'vitD',
        maxValue: 20, // Maximalwert bleibt unberührt
        maxValueMale19to25: 20, // 20 µg/Tag
        maxValueMale25to51: 20, // 20 µg/Tag
        maxValueMale51to65: 20,
        maxValueMale65plus: 20,
        maxValueFemale19to25: 20, // 20 µg/Tag
        maxValueFemale25to51: 20,
        maxValueFemale51to65: 20,
        maxValueFemale65plus: 20,
        maxValueChildren1to4: 20, // 20 µg/Tag
        maxValueChildren4to7: 20, // 20 µg/Tag
        maxValueChildren7to10: 20, // 20 µg/Tag
        maxValueChildren10to13: 20, // 20 µg/Tag
        maxValueChildren13to15Male: 20,
        maxValueChildren13to15Female: 20,
        maxValueChildren15to19Male: 20,
        maxValueChildren15to19Female: 20,
        maxValuePregnancy: 20, // 20 µg/Tag
        maxValueBreastfeeding: 20, // 20 µg/Tag
        OverdosePercentage: 500, // Beibehalten wie zuvor
        unit: 'µg',
        isVitamin: true,
    },
    {
        name: "LABEL_VITAMIN_E",
        tooltip: 'TOOLTIP_VITAMIN_E',
        key: 'vitE',
        maxValue: 12000, // 12 mg = 12000 µg
        maxValueMale19to25: 15000, // 15 mg = 15000 µg/Tag
        maxValueMale25to51: 14000, // 14 mg = 14000 µg/Tag
        maxValueMale51to65: 13000, // 13 mg = 13000 µg/Tag
        maxValueMale65plus: 12000, // 12 mg = 12000 µg/Tag
        maxValueFemale19to25: 12000, // 12 mg = 12000 µg/Tag
        maxValueFemale25to51: 12000, // 12 mg = 12000 µg/Tag
        maxValueFemale51to65: 12000, // 12 mg = 12000 µg/Tag
        maxValueFemale65plus: 11000, // 11 mg = 11000 µg/Tag
        maxValueChildren1to4: 5500, // Durchschnitt: (6 + 5) / 2 = 5,5 mg = 5500 µg/Tag
        maxValueChildren4to7: 8000, // 8 mg = 8000 µg/Tag
        maxValueChildren7to10: 9500, // Durchschnitt: (10 + 9) / 2 = 9,5 mg = 9500 µg/Tag
        maxValueChildren10to13: 12000, // Durchschnitt: (13 + 11) / 2 = 12 mg = 12000 µg/Tag
        maxValueChildren13to15Male: 14000,
        maxValueChildren13to15Female: 12000,
        maxValueChildren15to19Male: 15000,
        maxValueChildren15to19Female: 12000,
        maxValuePregnancy: 13000, // 13 mg = 13000 µg/Tag
        maxValueBreastfeeding: 17000, // 17 mg = 17000 µg/Tag
        OverdosePercentage: 500, // Beibehalten wie zuvor
        unit: 'µg',
        isVitamin: true,
    },
    {
        name: "LABEL_VITAMIN_K",
        tooltip: 'TOOLTIP_VITAMIN_K',
        key: 'vitK',
        maxValue: 70, // Maximalwert bleibt unberührt
        maxValueMale19to25: 70, // 70 µg/Tag
        maxValueMale25to51: 70, // 70 µg/Tag
        maxValueMale51to65: 80, // 80 µg/Tag
        maxValueMale65plus: 80, // 80 µg/Tag
        maxValueFemale19to25: 60, // 60 µg/Tag
        maxValueFemale25to51: 60, // 60 µg/Tag
        maxValueFemale51to65: 65, // 65 µg/Tag
        maxValueFemale65plus: 65, // 65 µg/Tag
        maxValueChildren1to4: 15, // 15 µg/Tag
        maxValueChildren4to7: 20, // 20 µg/Tag
        maxValueChildren7to10: 30, // 30 µg/Tag
        maxValueChildren10to13: 40, // 40 µg/Tag
        maxValueChildren13to15Male: 50,
        maxValueChildren13to15Female: 50,
        maxValueChildren15to19Male: 65,
        maxValueChildren15to19Female: 65,
        maxValuePregnancy: 60, // 60 µg/Tag
        maxValueBreastfeeding: 60, // 60 µg/Tag
        OverdosePercentage: 500, // Beibehalten wie zuvor
        unit: 'µg',
        isVitamin: true,
    },
    {
        name: "LABEL_ESSENTIELLEAMINOSAEUREN",
        key: 'EssentielleAminosaeuren',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    },
    {
        name: "LABEL_ISOLEUCIN",
        key: 'Isoleucin',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    },
    {
        name: "LABEL_LEUCIN",
        key: 'Leucin',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    },
    {
        name: "LABEL_LYSIN",
        key: 'Lysin',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_METHIONIN",
        key: 'Methionin',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_CYSTEIN",
        key: 'Cystein',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_PHENYLALANIN",
        key: 'Phenylalanin',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_TYROSIN",
        key: 'Tyrosin',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_THREONIN",
        key: 'Threonin',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_TRYPTOPHAN",
        key: 'Tryptophan',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_VALIN",
        key: 'Valin',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_ARGININ",
        key: 'Arginin',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_HISTIDIN",
        key: 'Histidin',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_NICHTESSENTIELLEAMINOSAEUREN",
        key: 'NichtessentielleAminosaeuren',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_ALANIN",
        key: 'Alanin',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_ASPARAGINSAEURE",
        key: 'Asparaginsaeure',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_GLUTAMINSAEURE",
        key: 'Glutaminsaeure',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_GLYCIN",
        key: 'Glycin',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_PROLIN",
        key: 'Prolin',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    }, {
        name: "LABEL_SERIN",
        key: 'Serin',
        maxValue: 70,
        unit: 'g',
        isProtine: true,
    },






    {
        name: "LABEL_ZUCKER",
        key: 'Zucker',
        maxValue: 70,
        unit: 'g',
        isCarbs: true,
    }, {
        name: "LABEL_MONOSACCHARIDE",
        key: 'Monosaccharide',
        maxValue: 70,
        unit: 'g',
        isCarbs: true,
    }, {
        name: "LABEL_GLUCOSE",
        key: 'Glucose',
        maxValue: 70,
        unit: 'g',
        isCarbs: true,
    }, {
        name: "LABEL_FRUCTOSE",
        key: 'Fructose',
        maxValue: 70,
        unit: 'g',
        isCarbs: true,
    }, {
        name: "LABEL_GALACTOSE",
        key: 'Galactose',
        maxValue: 70,
        unit: 'g',
        isCarbs: true,
    }, {
        name: "LABEL_DISACCHARIDE",
        key: 'Disaccharide',
        maxValue: 70,
        unit: 'g',
        isCarbs: true,
    }, {
        name: "LABEL_SACCHAROSE",
        key: 'Saccharose',
        maxValue: 70,
        unit: 'g',
        isCarbs: true,
    }, {
        name: "LABEL_MALTOSE",
        key: 'Maltose',
        maxValue: 70,
        unit: 'g',
        isCarbs: true,
    }, {
        name: "LABEL_LACTOSE",
        key: 'Lactose',
        maxValue: 70,
        unit: 'g',
        isCarbs: true,
    }, {
        name: "LABEL_OLIGOSACCARIDE_RESORBIERBAR",
        key: 'Oligosaccarideresorbierbar',
        maxValue: 70,
        unit: 'g',
        isCarbs: true,
    }, {
        name: "LABEL_OLIGOSACCARIDE_NICHT_RESORBIERBAR",
        key: 'Oligosaccaridenichtresorbierbar',
        maxValue: 70,
        unit: 'g',
        isCarbs: true,
    }, {
        name: "LABEL_FIBER",
        key: 'fiber',
        maxValue: 70,
        unit: 'g',
        isCarbs: true,
        isFiber: true,
    }, {
        name: "LABEL_GLYKOGEN",
        key: 'Glykogen',
        maxValue: 70,
        unit: 'g',
        isCarbs: true,
    }, {
        name: "LABEL_STAERKE",
        key: 'Staerke',
        maxValue: 70,
        unit: 'g',
        isCarbs: true,
    }, {
        name: "LABEL_ZUCKERALKOHOLE",
        key: 'Zuckeralkohole',
        maxValue: 70,
        unit: 'g',
        isCarbs: true,
    },






    {
        name: "LABEL_GESAETTIGTE_FETTSAEUREN",
        key: 'GesaettigteFettsaeuren',
        maxValue: 70,
        unit: 'g',
        isFats: true,
    }, {
        name: "LABEL_EINFACHUNGESAETTIGTEFETTSAEUREN",
        key: 'EinfachungesaettigteFettsaeuren',
        maxValue: 70,
        unit: 'g',
        isFats: true,
    }, {
        name: "LABEL_MEHRFACHUNGESAETTIGTEFETTSAEUREN",
        key: 'MehrfachungesaettigteFettsaeuren',
        maxValue: 70,
        unit: 'g',
        isFats: true,
    }, {
        name: "LABEL_OMEGA_3_FETTSAEUREN",
        key: 'Omega_3_Fettsaeuren',
        maxValue: 70,
        unit: 'g',
        isFats: true,
    }, {
        name: "LABEL_OMEGA_6_FETTSAEUREN",
        key: 'Omega_6_Fettsaeuren',
        maxValue: 70,
        unit: 'g',
        isFats: true,
    }, {
        name: "LABEL_KURZKETTIGE_FETTSAEUREN",
        key: 'KurzkettigeFettsaeuren',
        maxValue: 70,
        unit: 'g',
        isFats: true,
    }, {
        name: "LABEL_MITTELKETTIGEFETTSAEUREN",
        key: 'MittelkettigeFettsaeuren',
        maxValue: 70,
        unit: 'g',
        isFats: true,
    }, {
        name: "LABEL_LANGKETTIGEFETTSAEUREN",
        key: 'LangkettigeFettsaeuren',
        maxValue: 70,
        unit: 'g',
        isFats: true,
    }, {
        name: "LABEL_GLYCERINUNDLIPOIDE",
        key: 'GlycerinundLipoide',
        maxValue: 70,
        unit: 'g',
        isFats: true,
    }, {
        name: "LABEL_CHOLESTEROL",
        key: 'cholesterin',
        maxValue: 70,
        unit: 'g',
        isFats: true,
    }
];
export type INutritionType = {
    name: string;
    key: string;
    maxValue: number;
    maxValueFemale19to25?: number;
    maxValueFemale25to51?: number;
    maxValueFemale51to65?: number;
    maxValueFemale65plus?: number;
    maxValueMale19to25?: number;
    maxValueMale25to51?: number;
    maxValueMale51to65?: number;
    maxValueMale65plus?: number;
    OverdosePercentage?: number;
    unit: string;
    isMineral?: boolean;
    isTrace?: boolean;
    isVitamin?: boolean;
    isProtine?: boolean;
    isCarbs?: boolean;
    isFats?: boolean;
    isFiber?: boolean;
    tooltip?: string;
}
export const getIngredientUnitString = (unit?: number) => {
    switch (unit) {
        case 0: {
            return "LABEL_ML"
        }
        case 1: {
            return "LABEL_GR"
        }
        case 2: {
            return "LABEL_UNIT"
        }
        case 3: {
            return "LABEL_SLICE"
        }
        case 4: {
            return "LABEL_EL"
        }
        case 5: {
            return "LABEL_TL"
        }
        case 6: {
            return "LABEL_CUP"
        }
        case 7: {
            return "LABEL_PINCH"
        }
        case 8: {
            return "LABEL_CLOVE"
        }
        case 9: {
            return "LABEL_PK";
        }
        case 10: {
            return "LABEL_TUB";
        }
        case 11: {
            return "LABEL_ROLL";
        }
        case 12: {
            return "LABEL_LEAVES";
        }
        case 13: {
            return "LABEL_BUNCH";
        }
        case 14: {
            return "LABEL_HEAD";
        }
        case 15:{
            return "LABEL_PORTION_UNIT";
        }
        default: {
            return "-"
        }
    }
}
export { NutritionValues };
export const UnitOptions = [
    {
        label: "ml",
        value: 0,
    },
    {
        label: "g",
        value: 1,
    },
    {
        label: "portion",
        value: 2,
    },
    {
        label: "Sl",
        value: 3,
    },
    {
        label: "tbsp",
        value: 4,
    },
    {
        label: "tsp",
        value: 5,
    },
    {
        label: "scoop",
        value: 6,
    },
    {
        label: "pinch",
        value: 7,
    },
    {
        label: "clove",
        value: 8,
    },
    {
        label: "pk",
        value: 9,
    },
    {
        label: "tub",
        value: 10,
    },
    {
        label: "roll",
        value: 11,
    },
    {
        label: "leaves",
        value: 12,
    },
    {
        label: "bunch",
        value: 13,
    },
    {
        label: "head",
        value: 14,
    },
];